<template>
  <div>

    <div class="columns is-multiline">

      <div class="column is-full-mobile is-full-tablet is-7-desktop is-7-fullhd">
        <transition name="fade" mode="out-in">
          <router-view name="side_view"/>
        </transition>

      </div>

      <div class="column">
        <div class="tile is is-ancestor is-vertical">
          <div class="tile is-parent">
            <article class="tile is-child map">
              <div class="card has-table no-outside" style="overflow: hidden">
                <div v-bind:class="'main-map-div'" :style="{ overflow: 'hidden', width: '100%' }">
                  <MglMap :accessToken="accessToken"
                          ref="mymap"
                          :mapStyle.sync="mapStyle"
                          @load="onMapLoaded"
                          @click="event => print(event)"
                  >

                    <MglPopup v-if="currentBuilding" :coordinates="[currentBuilding.logitude, currentBuilding.latitude]"
                              anchor="top" :showed="true">
                      <b-tag>HELLO</b-tag>
                    </MglPopup>


                    <MglGeojsonLayer v-if="geoJsonSource"
                                     sourceId="buildings"
                                     :source="geoJsonSource"
                                     layerId="buildings"
                                     :layer="buildingLayer"
                                     :replace-source="false"
                                     :clear-source="true"
                    />

                    <MglGeojsonLayer v-if="geoJsonSource"
                                     sourceId="buildings"
                                     :source="geoJsonSource"
                                     layerId="buildings_icon"
                                     :layer="buildingIconLayer"
                                     :replace-source="false"
                                     :clear-source="true"
                    />

                    <MglGeojsonLayer v-if="geoJsonSource"
                                     sourceId="buildings"
                                     layerId="buildings_cluster_layer"
                                     :layer="clusterLayer"
                                     :replace-source="false"
                                     :clear-source="true"
                    />

                    <MglGeojsonLayer v-if="geoJsonSource"
                                     sourceId="buildings"
                                     layerId="buildings_cluster_count_layer"
                                     :layer="clusterCount"
                                     :replace-source="false"
                                     :clear-source="true"
                    />
                    <!--                                     @click="event => this.$router.push({name: 'buildings.map.detail', params: {code: event.code}, replace: true})"-->

                  </MglMap>
                </div>
              </div>
            </article>
          </div>


        </div>

      </div>

    </div>

  </div>
</template>

<script>

import Mapbox from 'mapbox-gl';
import {MglMap, MglGeojsonLayer, MglPopup} from "vue-mapbox";
import bbox from '@turf/bbox';
import math from "lodash";

export default {
  name: "BuildingsMap",
  components: {
    MglMap,
    MglGeojsonLayer,
    MglPopup
  },

  created() {
    this.mapbox = Mapbox;
    this.map = null;
  },

  data() {
    return {
      accessToken: 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ',
      mapStyle: 'mapbox://styles/luckasdc/cl8g1mb28003k14msrew82i8c',
      asyncActions: null,
      cu_build: null,
      geoJsonSource: {
        type: "geojson",
        data: {
          "type": "FeatureCollection",
          "features": []
        },
        cluster: true,
        clusterMaxZoom: 16, // Max zoom to cluster points on
        clusterRadius: 25 // Radius of each cluster when clustering points (defaults to 50)
      },
      clusterCount: {
        type: 'symbol',
        source: 'buildings',
        filter: ['has', 'point_count'],
        'paint': {
          'text-color': '#fff',
        },
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      },
      clusterLayer: {
        type: 'circle',
        source: "buildings",
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#989898",
            5,
            "#6B6B6B",
            10,
            "#443939"
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            12,
            5,
            15,
            20,
            18
          ]
        }
      },
      buildingLayer: {
        type: "circle",
        source: "buildings",
        filter: ["!", ["has", "point_count"]],
        paint: {
          'circle-color': [
            'match',
            ['get', 'color'],
            'Yellow',
            '#F0D70F',
            'Blue',
            '#1b87ef',
            /* other */ '#ccc'
          ],
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#443939"
        }
      },
      buildingIconLayer: {
        type: 'symbol',
        filter: ["!", ["has", "point_count"]],
        'paint': {
          'text-color': '#443939',
        },
        "layout": {
          'icon-image': `building`,
          'icon-size': 0.7,
          'text-allow-overlap': true,
          'text-ignore-placement': true,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-field': ["get", "code"],
          'text-font': [
            'Open Sans Bold',
            'Arial Unicode MS Bold'
          ],
          'text-size': 11,
          'text-transform': 'uppercase',
          'text-letter-spacing': 0.05,
          'text-offset': [0, 1.5]
        },
      }
    }

  },

  computed: {
    currentBuilding() {
      return this.$store.state.current_building
    },
    filteredBuildings() {
      return this.$store.state.filtered_buildings
    },
    buildings() {
      return this.$store.state.buildings
    }
  },
  watch: {
    currentBuilding(newBuilding) {
      // Our fancy notification (2).
      if (newBuilding && newBuilding.latitude) {
        this.cu_build = newBuilding
        this.map.flyTo({
          center: [newBuilding.logitude, newBuilding.latitude],
          duration: 500,
          zoom: math.max([15.5, this.map.getZoom()]),
          essential: true // this animation is considered essential with respect to prefers-reduced-motion
        })
      } else {
        this.cu_build = null
        this.map.fitBounds(bbox(this.geoJsonSource.data), {padding: 50, duration: 500});
      }
    },
    filteredBuildings: {
      deep: true,
      handler() {
        this.drawBuildings()
      }
    }
  },


  mounted() {
  },
  methods: {
    onMove(e) {
      const coords = e.lngLat;
      // Set a UI indicator for dragging.
      this.map.getCanvas().style.cursor = 'grabbing';
      var features = this.map.queryRenderedFeatures(e.originalEvent.point);

      // Update the Point feature in `geojson` coordinates
      // and call setData to the source layer `point` on it.
      let newdata = this.geoJsonSource
      newdata.data.features[0].geometry.coordinates = [coords.lng, coords.lat]// TODO
      console.log(this.buildings.find(a => a.code === features[0].properties.code))
      this.map.getSource('buildings').setData(newdata);
    },

    onUp() {
      // Unbind mouse/touch events
      this.map.off('mousemove', this.onMove);
      //this.map.off('touchmove', this.onMove);

    },

    print(event) {

      if (this.$store.state.relocating) {
        this.$store.dispatch("setRelocatedLocation", event.mapboxEvent.lngLat)
        return
      }

      var features = this.map.queryRenderedFeatures(event.mapboxEvent.point);

      if (features.length > 0 && features[0].layer.id === "buildings_icon") {
        this.$router.push({name: 'buildings.map.detail', params: {code: features[0].properties.code}, replace: true})
            .catch(() => {
            });
        return
      }

      // Cluster clicked, zoom in
      if (features.length > 0 && features[0].layer.id === "buildings_cluster_count_layer") {
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('buildings').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return;

              this.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              });
            }
        );
      }

    },
    async onMapLoaded(event) {
      // in component
      this.map = event.map;
      this.asyncActions = event.component.actions;
      //this.fetchData()
      this.drawBuildings()
    },

    fetchData() {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch("fetchAllBuildings").then(_ => {
        this.drawBuildings()
        this.map.fitBounds(bbox(this.geoJsonSource.data), {padding: 50, duration: 80});
      })
      this.$store.dispatch('setTitle', {
        back: true,
        stack: [
          {
            message: "Gebouwen",
          }
        ]
      })
    },

    drawBuildings() {
      this.geoJsonSource.data.features = this.filteredBuildings.map(i => i["geo"]).filter(i => i)
    },
  }
}
</script>

<style lang="scss">

@media(max-width: 767px) { /* <== You can change this break point as per your  needs */
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

.tile.is-child.map {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.main-map-div {
  height: 85vh;
  transition: all 500ms ease;
}

#map {
  width: 100%;
}

#wrapper {
  position: relative;
}

#over_map {
  position: absolute;
  top: 20px;
  left: 20px;

  z-index: 90;
}


#app .marker {
  z-index: 10;
}


</style>
